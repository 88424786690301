/* SCSS HEX */
$dark-sienna: #36151eff;
$indigo-dye: #0b4870ff;
$deep-saffron: #f8983fff;
$cultured: #f7f7f7ff;
$blue-munsell: #5497a7ff;

// Update your variables before the import of the base bootstrap files
$primary: #0b4870;
$secondary: #5497a7;
$light: #f7f7f7;
$warning: #f8983f;
$dark: hsl(204, 82%, 10%);

// import the functions and variables
// https://getbootstrap.com/docs/5.0/customize/sass/#modify-map

@import "../../node_modules/bootstrap/scss/_functions";
@import "../../node_modules/bootstrap/scss/_variables";

$custom-theme-colors: (
  "altlight": #f2ebfa,
  "altdark": #522192
);

// Then merge your map with the $theme-colors and assign to $theme-colors
$theme-colors: map-merge($custom-theme-colors, $theme-colors);

@import '../../node_modules/bootstrap/scss/bootstrap';






/* To push down the content below the navbar */
body::before {
  display:block;
  content: '';
  height: 60px;
}


/* body {
  margin-top: 60px;
} */

/* On medium screens and above, make width 50% */
@media (min-width: 768px) {
  .news-input {
    width: 50%;
  }
}

#map {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

